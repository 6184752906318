import { Keys } from 'locales/keys';
import { sprintf } from 'sprintf-js';
import { LanguageCode } from 'modules/site/enums';
import { contains } from 'lodash';
import { LanguageMaterialDateFormat } from 'modules/site/enums/language-date-format';
import { isNil } from './lodash-extended';

export class LocalizationService {
  public static getDateFormat(): LanguageMaterialDateFormat {
    switch (LocalizationService.getUserLanguage()) {
      case LanguageCode.Spanish: {
        return LanguageMaterialDateFormat.Spanish;
      }
      case LanguageCode.English: {
        return LanguageMaterialDateFormat.English;
      }
      default: {
        return LanguageMaterialDateFormat.SiteDefault;
      }
    }
  }

  public static getLocaleDateString(date: Date, options?): string {
    if (isNil(date)) {
      return '';
    }
    return date.toLocaleDateString(this.getUserLanguage(), options);
  }

  public static getLongLocaleDateString(date: Date): string {
    return this.getLocaleDateString(date, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  public static getMediumLocaleDateString(date: Date): string {
    return this.getLocaleDateString(date, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  public static getShortLocaleDateString(date: Date): string {
    return this.getLocaleDateString(date, {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    });
  }

  public static getUserLanguage(): LanguageCode {
    const supportedLanguages = [LanguageCode.English, LanguageCode.Spanish];
    let userLanguage = window.userLocale || navigator.language || (<any>navigator).userLanguage;
    //normalize language code
    userLanguage = userLanguage.substr(0, 2);

    if (!contains(supportedLanguages, userLanguage)) {
      console.log(
        `current locale is ${userLanguage} unsupported, current supported locales are: ${supportedLanguages}`,
      );
      userLanguage = LanguageCode.English;
    }

    return userLanguage;
  }

  public static localize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    if (!window.localStrings) {
      throw new Error('ERROR: localStrings is missing.');
    }
    if (translation && args && Object.keys(args).length > 0) {
      return sprintf(window.localStrings[translation.key], args);
    } else if (translation) {
      return window.localStrings[translation.key];
    } else {
      throw new Error('ERROR: blank translation passed to localize.');
    }
  };

  public static labelize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    const phrase = LocalizationService.localize(translation, args);

    return LocalizationService.localize(Keys.CommonTerms.Label, { label: phrase });
  };

  public static parenthesize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    const phrase = LocalizationService.localize(translation, args);

    return LocalizationService.localize(Keys.CommonTerms.Parentheses, { text: phrase });
  };

  public localize: <T extends TTranslation>(translation: T, args: InstanceType<T>) => string;
  public labelize: <T extends TTranslation>(translation: T, args: InstanceType<T>) => string;
  public parenthesize: <T extends TTranslation>(translation: T, args: InstanceType<T>) => string;

  constructor() {
    this.localize = LocalizationService.localize;
    this.labelize = LocalizationService.labelize;
    this.parenthesize = LocalizationService.parenthesize;
  }
}
