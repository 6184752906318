import { IVariablesFromServer } from '../models/variables-from-server';

export enum Feature {
  AccreditationSideMenu = 'ACCREDITATION_SIDE_MENU',
  Alerts = 'ALERTS',
  AlternativeHomePage = 'ALTERNATIVE_HOME_PAGE',
  ApiGateway = 'API_GATEWAY',
  Assessments = 'ASSESSMENTS',
  AssessmentExport = 'ASSESSMENT_EXPORT',
  AuthorBeta = 'AUTHOR_BETA',
  BreadcrumbDeepLink = 'BREADCRUMB_DEEPLINK',
  CaleaFreemium = 'CALEA_FREEMIUM',
  Communities = 'COMMUNITIES',
  CommunityAdminSelfServe = 'COMMUNITY_ADMIN_SELFSERVE',
  ContentHubBulkImport = 'CONTENT_HUB_BULK_IMPORT',
  ContentHubInvitations = 'CONTENT_HUB_INVITATIONS',
  CpvDocViewer = 'CPV_DOCVIEWER',
  DisableSpaForIE11 = 'DISABLE_SPA_FOR_IE11',
  Documents = 'DOCUMENTS',
  DocumentComparison = 'DOCUMENT_COMPARISON',
  DocumentDiscussion = 'DOCUMENT_DISCUSSION',
  DocumentsAndFoldersSqlLookup = 'DOCUMENTS_AND_FOLDERS_SQL_LOOKUP',
  DocumentSignatures = 'DOCUMENT_SIGNATURES',
  DocumentWorkflows = 'DOCUMENT_WORKFLOWS',
  EmbeddedAnalytics = 'EMBEDDED_ANALYTICS',
  Folders = 'FOLDERS',
  FolderMigrationEnabled = 'FOLDER_MIGRATION_ENABLED',
  FolderSecurity = 'FOLDER_SECURITY',
  GainsightTrackAllUsers = 'GAINSIGHT_TRACK_ALL_USERS',
  GoogleDriveIntegration = 'GOOGLE_DRIVE_INTEGRATION',
  HideLicenseWarnings = 'HIDE_LICENSE_WARNINGS',
  HubPublisher = 'HUB_PUBLISHER',
  HubSubscriber = 'HUB_SUBSCRIBER',
  Localization = 'LOCALIZATION',
  ManualsPublisher = 'MANUALS_PUBLISHER',
  ManualsSubscriber = 'MANUALS_SUBSCRIBER',
  MedPassFreemium = 'MEDPASS_FREEMIUM',
  ModernizationBulkPublish = 'MODERNIZATION_BULK_PUBLISH',
  ModernizationBulkWorkflow = 'MODERNIZATION_BULK_WORKFLOW',
  NewAssessmentsAttachmentTrayMenu = 'NEW_ASSESSMENT_ATTACHMENT_TRAY_MENU',
  NewSearchIndexing = 'NEW_SEARCH_INDEXING',
  NewSearchQuerying = 'NEW_SEARCH_QUERYING',
  OneDriveIntegration = 'ONEDRIVE_INTEGRATION',
  OptionalSsoLogin = 'OPTIONAL_SSO_LOGIN',
  PartnerIntegrationsFilePicker = 'PARTNER_INTEGRATIONS_FILEPICKER',
  PublicFacingDocuments = 'PUBLIC_FACING_DOCUMENTS',
  ReactNewDocumentPage = 'REACT_NEW_DOCUMENT_PAGE',
  // @TODO: Deprecate this.
  SidebarPin = 'SIDEBAR_PIN',
  // @TODO: Deprecate this.
  SinglePage = 'SINGLE_PAGE',
  SpaOs = 'SPA_OS',
  SSO = 'SINGLE_SIGN_ON',
  StandardsPartnerReports = 'STANDARDS_PARTNER_REPORTS',
  Surveys = 'SURVEYS',
  Tests = 'TESTS',
  Training = 'TRAINING',
  UnifiedIdentityUserManagement = 'UNIFIED_IDENTITY_USER_MANAGEMENT',
  UnifiedIdentityAuth = 'UNIFIED_IDENTITY_AUTH',
  UseEidTokensForAuth0 = 'USE_EID_TOKENS_FOR_AUTH0',
  UsersAndGroupsSqlLookup = 'USERS_AND_GROUPS_SQL_LOOKUP',
  Websockets = 'WEBSOCKETS',
  PowerAccredPartnerAccess = 'POWER_ACCRED_PARTNER_ACCESS',
  PowerAccredAssessorAccess = 'POWER_ACCRED_ASSESSOR_ACCESS',
  PowerAccredAccountAccess = 'POWER_ACCRED_ACCOUNT_ACCESS',
  ModernizationNewDocument = 'MODERNIZATION_NEW_DOCUMENT',
  ModernizationDocumentViewer = 'MODERNIZATION_DOCUMENT_VIEWER',
  ModernizationDocumentManage = 'MODERNIZATION_DOCUMENT_MANAGE',
  NewSearchApiIndexing = 'NEW_SEARCH_API_INDEXING',
  NewSearchApiQuerying = 'NEW_SEARCH_API_QUERYING',
  PowersuiteEidAuth = 'POWERSUITE_EID_AUTH',
  ModernizationBulkActions = 'MODERNIZATION_BULK_ACTIONS',
  ModernizationWorkflow = 'MODERNIZATION_WORKFLOW',
  ModernizationAttributePicker = 'MODERNIZATION_ATTRIBUTE_PICKER',
  ModernizationStandards = 'MODERNIZATION_STANDARDS',
  DocumentSignatureExceptions = 'DOCUMENT_SIGNATURE_EXCEPTIONS',
  AssessmentExportPartial = 'ASSESSMENT_EXPORT_PARTIAL',
  EidManagerPasswords = 'EID_MANAGED_PASSWORDS',
  ModernizationMenus = 'MODERNIZATION_MENUS',
  ModernizationHomepage = 'MODERNIZATION_HOMEPAGE',
  ModernizationUsers = 'MODERNIZATION_USERS',
  ModernizationGroups = 'MODERNIZATION_GROUPS',
  ModernizationFolders = 'MODERNIZATION_FOLDERS',
  ModernizationAdministrationMenu = 'MODERNIZATION_ADMINISTRATION_MENU',
  ModernizationAccountSettings = 'MODERNIZATION_ACCOUNT_SETTINGS',
  ModernizationNoAJS = 'MODERNIZATION_NO_AJS',
  ModernizationAdvancedSearch = 'MODERNIZATION_ADVANCEDSEARCH',
  MacroEnabledDocuments = 'MACRO_ENABLED_DOCUMENTS',
  CustomEmailSenderAddress = 'CUSTOM_EMAIL_SENDER_ADDRESS',
  PowerRecall = 'POWER_RECALL',
  ModernizationInboxDocuments = 'MODERNIZATION_INBOX_DOCUMENTS',
  PowerRecallTrial = 'POWER_RECALL_TRIAL',
  PowerRecallTrialPromo = 'POWER_RECALL_TRIAL_PROMO',
  ModernizationCourses = 'MODERNIZATION_COURSES',
  ModernizationCertificates = 'MODERNIZATION_CERTIFICATES',
  ModernizationTests = 'MODERNIZATION_TESTS',
  ModernizationSurveys = 'MODERNIZATION_SURVEYS',
}

export type FeatureFlags = { [key in Feature]: boolean };

export class FeatureService {
  private static get siteFeatures(): Partial<FeatureFlags> {
    const vars: Partial<IVariablesFromServer> = window.top.variablesFromServer || {};

    return vars.FeatureFlagsForSite || {};
  }

  private static get userFeatures(): Partial<FeatureFlags> {
    const vars: Partial<IVariablesFromServer> = window.top.variablesFromServer || {};

    return vars.FeatureFlags || {};
  }

  public static siteHas(feature: Feature): boolean {
    return this.siteFeatures[feature] || false;
  }

  public static userHas(feature: Feature): boolean {
    return this.userFeatures[feature] || false;
  }

  public static userHasAny(...features: Feature[]): boolean {
    return features.some((feature) => this.userFeatures[feature]);
  }
}
