import axios from 'axios';
import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { stringStartsWith } from '../utilities/utils';
import { isNil } from './lodash-extended';

const HttpService = axios.create({
  withCredentials: true,
});

declare var window: any;
const vars: any = window['variablesFromServer'] || {};

// Optional chaining for unit tests.
HttpService.interceptors?.request?.use?.(addCsrfToHeaders);

export function addCsrfToHeaders(config) {
  const csrfData = getCsrfHeadersForUrl(config.url);

  if (isNil(config.headers)) {
    config.headers = {};
  }

  if (!isNil(csrfData)) {
    config.headers['CSRF-TOKEN'] = csrfData.csrfToken;
    config.headers['X-CSRF-HASH'] = csrfData.xCsrfHash;
  }
  return config;
}

/**
 * Returns Csrf token/hash if the url is going out to our CCMP api, otherwise it returns null.
 */
export function getCsrfHeadersForUrl(url: string) {
  /**
   * We are getting the store from the top frame since we have webforms pages
   * that are inside of iframes that make use of the HttpService.
   * And we are using option chaining because some apps (like pub docs app) don't have the
   * store in the top frame, and can also have a completely different store all together.
   */
  const app = window.top.store?.getState?.()?.app;

  // when we delete AJS we don't have a store anymore, let's just return from variablesFromServer
  if (!app?.csrfToken) {
    return {
      csrfToken: window['variablesFromServer']?.csrfToken,
      xCsrfHash: window['variablesFromServer']?.xCsrfHash,
    };
  }

  /**
   * We are stripping out the ":80" from apiBaseUrl if it exists since that port number does not
   * show up inside of the request url. Currently only our dev local env has ":80" in the apiBaseUrl
   */
  const apiUrl = window.top.apiBaseUrl?.split?.(':80')?.[0];
  if (!isNil(apiUrl) && stringStartsWith(url, apiUrl)) {
    return {
      csrfToken: app?.csrfToken,
      xCsrfHash: app?.xCsrfHash,
    };
  }

  return null;
}

function createServerSideErrorResponse(errorCode: ErrorCode) {
  return { response: { data: { error: { code: errorCode } } } };
}

export { HttpService, createServerSideErrorResponse };
